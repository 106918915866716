export default function PawPath() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="179.296" height="1385.417" viewBox="0 0 179.296 1385.417">
            <g id="Paw_Pattern" data-name="Paw Pattern" transform="translate(-11.752 -10.452)">
                <g id="Group_1273" data-name="Group 1273" transform="translate(11.752 10.452)">
                    <g id="Group_1272" data-name="Group 1272">
                        <g id="Group_1259" data-name="Group 1259" transform="translate(52.282 1327.334)">
                            <path
                                id="Path_152"
                                data-name="Path 152"
                                d="M94.326,813.438c-1.4,2.37-2.835,2.661-3.922,2.344a4.063,4.063,0,0,1-1.817-1.238c.639-1.172,1.151-2.355,1.151-2.355-3.487-3.327-10.413-7.844-13.413-9.792,0,0,.618-3.078,2.821-2.574a4.2,4.2,0,0,0,1.771.045,10.367,10.367,0,0,0,5.453-3.64c.493-.744.246,1.97-4.413,5.537,0,0,3.455,2.17,5.11,3.539,1.608,1.327,3.57,2.945,3.57,2.945.03-6.017-3.45-14.47-3.45-14.47s2.821-2.59,3.8-.517a47.842,47.842,0,0,1,2.087,5.941c.374,1.412.822,4.314,1.291,7.6,0,0,.853-1.484,1.822-3.518a27.294,27.294,0,0,0,2.374-6.758,5.874,5.874,0,0,1-3.246.242c-2.649-.93-2.663-1.646-2.2-1.442,1.895.833,5.518-.839,6.418-1.836,0,0,2.511-.444,3.034.827a3.909,3.909,0,0,1-.256,2.433,64.571,64.571,0,0,1-6.74,14.823C95.166,812.171,94.485,813.2,94.326,813.438Z"
                                transform="translate(-16.141 -757.805)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_153"
                                data-name="Path 153"
                                d="M59.352,794.093a56.578,56.578,0,0,1-15.534-6.637,4.475,4.475,0,0,1-1.954-1.783c-.561-1.416,1.576-2.872,1.576-2.872,1.355.165,5.136-1.1,5.922-3.116.192-.488.679.085-.576,2.727a5.568,5.568,0,0,1-2.48,2.142c3.616,2.964,10.394,5.012,10.394,5.012a80.362,80.362,0,0,1-4.567-6.834,45.949,45.949,0,0,1-2.682-6.193c-.728-2.344,3.13-2.249,3.13-2.249s.836,2.283,2.2,5.143a36.24,36.24,0,0,0,5.572,8.526s.225-2.644.409-4.811c.1-1.115.385-2.691.644-3.987s.51-2.316.51-2.316c-5.845.54-7.923-1.439-7.045-1.2a9.874,9.874,0,0,0,6.493-.981,4.213,4.213,0,0,0,1.259-1.263c1.264-1.9,3.828,0,3.828,0-.89,3.525-2.63,11.8-2.611,16.858,0,0,1.214.51,2.5.906,0,0,.6,4.877-4.784,3.415C61.282,794.518,60.064,794.25,59.352,794.093Z"
                                transform="translate(-41.77 -772.561)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1260" data-name="Group 1260" transform="translate(20.407 1217.44)">
                            <path
                                id="Path_154"
                                data-name="Path 154"
                                d="M71.438,754.825c-3.48,4.311-5.838.228-5.838.228.808-1.069,1.489-2.172,1.489-2.172-2.882-3.873-9.3-9.3-11.915-11.687,0,0,1.083-2.978,3.175-2.16a4.159,4.159,0,0,0,1.736.3,10.393,10.393,0,0,0,5.93-2.844c.6-.672-.061,2.01-5.2,4.889,0,0,3.147,2.647,4.614,4.248l3.137,3.455a34.172,34.172,0,0,0-.423-9.685c-.413-2.992-.953-5.235-.953-5.235s3.165-2.181,3.825.033c0,0,.221.866.474,2.081s.54,2.78.718,4.164a72.935,72.935,0,0,1,.256,7.754s4.305-5.434,5.552-9.621a5.9,5.9,0,0,1-3.262-.232c-2.5-1.315-2.379-2.041-1.965-1.768,1.738,1.115,5.563-.024,6.6-.892,0,0,2.532-.07,2.895,1.275a3.919,3.919,0,0,1-.571,2.39,61.282,61.282,0,0,1-8.773,13.8C72.456,753.688,71.629,754.609,71.438,754.825Z"
                                transform="translate(0.046 -691.855)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_155"
                                data-name="Path 155"
                                d="M39.689,730.151a54.081,54.081,0,0,1-14.52-8.588,4.356,4.356,0,0,1-1.665-2.02c-.343-1.474,1.945-2.632,1.945-2.632,1.324.338,5.239-.423,6.273-2.309.251-.456.665.172-.918,2.62a5.661,5.661,0,0,1-2.759,1.8,26.784,26.784,0,0,0,5.93,4.448,39.434,39.434,0,0,0,3.628,1.877,64.858,64.858,0,0,1-3.546-7.365,54.561,54.561,0,0,1-1.85-6.472c-.427-2.41,3.391-1.817,3.391-1.817s.542,2.365,1.538,5.368a34.508,34.508,0,0,0,4.3,9.177s.134-.646.326-1.557c.216-.913.493-2.09.744-3.151.528-2.187,2.046-6.1,2.046-6.1-5.873-.228-7.686-2.45-6.845-2.1a9.919,9.919,0,0,0,6.571-.127,4.205,4.205,0,0,0,1.411-1.085c1.494-1.716,3.8.5,3.8.5-1.263,3.361-4.359,11.38-4.863,16.349,0,0,1.144.662,2.381,1.223,0,0,0,4.887-5.18,2.738Z"
                                transform="translate(-23.469 -709.464)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1261" data-name="Group 1261" transform="translate(3.097 1103.221)">
                            <path
                                id="Path_156"
                                data-name="Path 156"
                                d="M56.487,693.28c-2.008,1.916-3.462,1.8-4.417,1.188a4.067,4.067,0,0,1-1.393-1.707c.942-.96,1.764-1.97,1.764-1.97-2.355-4.217-7.911-10.438-10.278-13.122,0,0,1.407-2.813,3.4-1.74a4.226,4.226,0,0,0,1.7.517,10.286,10.286,0,0,0,6.221-2.074c.671-.59-.28,1.984-5.716,4.191,0,0,2.764,3.03,4,4.807,1.19,1.728,2.644,3.835,2.644,3.835,1.669-5.842.5-14.992.5-14.992s3.393-1.764,3.807.512a48.842,48.842,0,0,1,.453,6.347,73.245,73.245,0,0,1-.785,7.745s4.988-4.856,6.784-8.867a5.85,5.85,0,0,1-3.2-.646c-2.313-1.623-2.139-2.323-1.747-2,1.611,1.322,5.547.676,6.679-.054,0,0,2.541.249,2.712,1.634a3.975,3.975,0,0,1-.885,2.308A61.773,61.773,0,0,1,58.2,691.8C57.649,692.28,56.707,693.09,56.487,693.28Z"
                                transform="translate(7.706 -623.356)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_157"
                                data-name="Path 157"
                                d="M28.2,664.915a56.236,56.236,0,0,1-13.244-10.321,4.383,4.383,0,0,1-1.418-2.208c-.169-1.5,2.24-2.367,2.24-2.367,1.277.5,5.256.226,6.505-1.515.3-.422.641.253-1.217,2.482a5.62,5.62,0,0,1-2.952,1.439c2.712,3.764,8.714,7.453,8.714,7.453a79.16,79.16,0,0,1-2.616-7.742,54.7,54.7,0,0,1-1.082-6.641c-.141-2.442,3.583-1.381,3.583-1.381s1.05,9.651,4.062,15.14c0,0,.909-2.494,1.653-4.537.78-2.1,2.747-5.793,2.747-5.793-5.81-.951-7.352-3.379-6.557-2.924a9.96,9.96,0,0,0,6.545.685,4.122,4.122,0,0,0,1.527-.9c1.686-1.517,3.72.965,3.72.965-1.669,3.179-5.591,10.737-6.871,15.616,0,0,1.036.8,2.179,1.512a4.394,4.394,0,0,1-.946,2.081c-.754.862-2.144,1.386-4.563-.005C29.949,665.817,28.844,665.248,28.2,664.915Z"
                                transform="translate(-13.53 -643.883)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1262" data-name="Group 1262" transform="translate(0 987.717)">
                            <path
                                id="Path_158"
                                data-name="Path 158"
                                d="M49.283,630.257c-4.466,3.32-5.7-1.226-5.7-1.226,1.054-.838,1.994-1.74,1.994-1.74C43.76,622.817,39.018,615.96,37,613.006c0,0,1.743-2.621,3.59-1.313a4.207,4.207,0,0,0,1.62.721,10.3,10.3,0,0,0,6.428-1.3c.738-.505-.524,1.937-6.192,3.462,0,0,2.367,3.347,3.375,5.263l2.147,4.131c2.379-5.6,2.35-14.827,2.35-14.827s3.584-1.338,3.715.974a48.985,48.985,0,0,1-.336,6.359,73.368,73.368,0,0,1-1.738,7.6s5.551-4.213,7.829-7.977a5.833,5.833,0,0,1-3.093-1.031c-2.1-1.895-1.834-2.569-1.487-2.2,1.435,1.51,5.42,1.35,6.634.763,0,0,2.491.557,2.489,1.954a3.994,3.994,0,0,1-1.163,2.184,61.9,61.9,0,0,1-12.01,11.241Z"
                                transform="translate(6.974 -554.429)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_159"
                                data-name="Path 159"
                                d="M24.849,598.834a53.082,53.082,0,0,1-11.979-11.84,4.322,4.322,0,0,1-1.118-2.36c.028-1.51,2.531-2.078,2.531-2.078,1.2.648,5.185.859,6.65-.718.355-.381.6.327-1.531,2.315a5.681,5.681,0,0,1-3.116,1.071,26.613,26.613,0,0,0,4.661,5.746c1.635,1.6,3.029,2.7,3.029,2.7a71.818,71.818,0,0,1-1.6-7.992,54.366,54.366,0,0,1-.207-6.716c.178-2.438,3.734-.939,3.734-.939s-.226,9.7,2.066,15.506l.725-1.43c.434-.831.993-1.905,1.5-2.872,1.047-1.991,3.48-5.415,3.48-5.415-5.638-1.644-6.852-4.237-6.124-3.692a9.909,9.909,0,0,0,6.4,1.468,4.18,4.18,0,0,0,1.634-.711c1.869-1.3,3.563,1.406,3.563,1.406-1.035,1.48-2.773,3.969-4.488,6.679-.859,1.355-1.71,2.766-2.468,4.129a33.487,33.487,0,0,0-1.773,3.854s.954.921,2.02,1.764c0,0-1.169,4.736-5.683,1.393C26.5,599.942,25.459,599.242,24.849,598.834Z"
                                transform="translate(-11.752 -577.565)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1263" data-name="Group 1263" transform="translate(10.892 872.8)">
                            <path
                                id="Path_160"
                                data-name="Path 160"
                                d="M49.833,566.8c-4.772,2.769-5.54-1.9-5.54-1.9,1.132-.707,2.158-1.493,2.158-1.493-1.346-4.669-5.411-12.059-6.918-15.219,0,0,2.09-2.391,3.743-.874a4.129,4.129,0,0,0,1.508.907,10.37,10.37,0,0,0,6.559-.524c.8-.413-.785,1.858-6.62,2.7l1.036,2.053c.564,1.153,1.3,2.562,1.693,3.576.772,1.966,1.716,4.366,1.716,4.366,1.5-2.64,2.343-6.267,3.105-9.208.7-2.947,1.01-5.239,1.01-5.239s3.741-.9,3.553,1.407a49.479,49.479,0,0,1-1.207,6.268c-.378,1.433-1.289,4.218-2.506,7.352,0,0,5.922-3.53,8.6-7a5.743,5.743,0,0,1-2.928-1.392c-1.818-2.13-1.468-2.766-1.172-2.36,1.216,1.668,5.194,1.984,6.479,1.547,0,0,2.395.85,2.2,2.236a4.245,4.245,0,0,1-1.412,2.033,60.087,60.087,0,0,1-13.069,9.752C51.186,566.1,50.089,566.664,49.833,566.8Z"
                                transform="translate(-2.039 -486.234)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_161"
                                data-name="Path 161"
                                d="M29.327,532.846A56.508,56.508,0,0,1,18.879,519.7a4.39,4.39,0,0,1-.86-2.477c.188-1.5,2.733-1.768,2.733-1.768,1.123.786,5.056,1.461,6.678.066.394-.338.563.4-1.766,2.12a5.619,5.619,0,0,1-3.206.7c1.752,4.29,6.719,9.292,6.719,9.292a78.717,78.717,0,0,1-.725-8.132,54.657,54.657,0,0,1,.507-6.7c.435-2.4,3.806-.5,3.806-.5s-1.244,9.614.4,15.656c0,0,1.468-2.207,2.672-4.013,1.251-1.857,4.028-4.976,4.028-4.976-5.424-2.3-6.352-5.016-5.687-4.387a9.976,9.976,0,0,0,6.2,2.21,4.121,4.121,0,0,0,1.7-.516c1.994-1.075,3.388,1.817,3.388,1.817-2.369,2.694-7.954,9.107-10.344,13.538,0,0,.82,1.022,1.763,1.984,0,0-1.82,4.547-5.84.707C30.815,534.137,29.876,533.322,29.327,532.846Z"
                                transform="translate(-18.006 -511.584)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1264" data-name="Group 1264" transform="translate(34.971 760.074)">
                            <path
                                id="Path_162"
                                data-name="Path 162"
                                d="M57.6,503.961c-5.037,2.207-5.295-2.532-5.295-2.532,1.2-.573,2.3-1.237,2.3-1.237a36.222,36.222,0,0,0-1-4.051c-.408-1.454-.862-2.971-1.352-4.448-.972-2.952-2.013-5.732-2.633-7.4,0,0,2.337-2.141,3.811-.442a4.158,4.158,0,0,0,1.4,1.075,10.342,10.342,0,0,0,6.571.228c.843-.319-.984,1.761-6.869,1.926,0,0,1.482,3.8,1.994,5.9.244,1.017.514,2.144.725,3.018s.381,1.5.381,1.5c3.626-4.9,5.887-13.867,5.887-13.867s3.814-.47,3.374,1.808a49.126,49.126,0,0,1-1.886,6.1c-.545,1.383-1.926,4.016-3.513,6.987,0,0,1.554-.714,3.569-1.8a27.6,27.6,0,0,0,5.96-4.14,5.759,5.759,0,0,1-2.755-1.722c-1.573-2.329-1.155-2.923-.906-2.485,1.024,1.8,4.939,2.571,6.265,2.282,0,0,2.285,1.12,1.94,2.48A4.052,4.052,0,0,1,73.892,495a66.446,66.446,0,0,1-14.212,8.175Z"
                                transform="translate(-18.641 -419.909)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_163"
                                data-name="Path 163"
                                d="M41.391,467.859a56.823,56.823,0,0,1-8.929-14.226,4.372,4.372,0,0,1-.582-2.553c.352-1.465,2.91-1.447,2.91-1.447,1.029.9,4.861,2.017,6.627.813.428-.291.516.456-1.989,1.907a5.626,5.626,0,0,1-3.262.334c1.268,4.453,5.65,9.978,5.65,9.978a78.787,78.787,0,0,1,.178-8.154,54.231,54.231,0,0,1,1.244-6.594c.7-2.337,3.835-.068,3.835-.068s-2.3,9.4-1.336,15.59l3.1-3.685c1.447-1.7,4.551-4.49,4.551-4.49-5.134-2.888-5.758-5.692-5.166-4.993a9.992,9.992,0,0,0,5.916,2.888,4.143,4.143,0,0,0,1.743-.322c2.1-.846,3.166,2.182,3.166,2.182-2.651,2.412-8.909,8.156-11.772,12.289,0,0,.7,1.108,1.531,2.168,0,0-2.309,4.312-5.878.045C42.725,469.306,41.882,468.392,41.391,467.859Z"
                                transform="translate(-31.831 -446.86)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1265" data-name="Group 1265" transform="translate(67.072 652.205)">
                            <path
                                id="Path_164"
                                data-name="Path 164"
                                d="M72.6,442.894c-5.255,1.644-4.983-3.1-4.983-3.1,1.257-.439,2.428-.979,2.428-.979a57.175,57.175,0,0,0-1.526-8.761c-.711-3.128-1.514-6.056-1.992-7.8,0,0,2.5-1.968,3.821-.108a4.206,4.206,0,0,0,1.3,1.223,9.937,9.937,0,0,0,6.512.8c.867-.263-1.137,1.752-6.989,1.379,0,0,1.146,4.041,1.466,6.261.322,2.1.716,4.655.716,4.655a23.856,23.856,0,0,0,2.778-4c.857-1.517,1.616-3.1,2.25-4.537,1.266-2.874,2.022-5.162,2.022-5.162s3.842-.192,3.2,2.151a53.868,53.868,0,0,1-2.43,6.218c-.341.695-.9,1.7-1.616,2.9-.7,1.156-1.54,2.477-2.475,3.877,0,0,1.641-.536,3.7-1.421a23.689,23.689,0,0,0,6.266-3.715,5.726,5.726,0,0,1-2.574-2.059c-1.346-2.583-.878-3.163-.671-2.686.853,1.973,4.671,3.128,6.016,2.931,0,0,2.167,1.379,1.691,2.8a4.251,4.251,0,0,1-1.836,1.825,36.8,36.8,0,0,1-4.408,2.581c-1.439.733-2.839,1.372-4.149,1.921a54.255,54.255,0,0,1-6.35,2.243Z"
                                transform="translate(-38.2 -357.91)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_165"
                                data-name="Path 165"
                                d="M59.878,405.084a61.682,61.682,0,0,1-8.966-13.893,4.06,4.06,0,0,1-.606-2.423c.333-1.371,2.882-1.311,2.882-1.311,1.036.871,4.864,2,6.617.869.425-.273.517.448-1.968,1.792a5.787,5.787,0,0,1-3.246.259c1.308,4.241,5.688,9.7,5.688,9.7a74.4,74.4,0,0,1,.131-7.846,49.8,49.8,0,0,1,1.195-6.3c.679-2.229,3.835-.044,3.835-.044S63.2,394.9,64.178,400.936l3.071-3.515c1.435-1.625,4.528-4.314,4.528-4.314C66.644,390.24,66,387.546,66.6,388.22a10.283,10.283,0,0,0,5.925,2.837,4.214,4.214,0,0,0,1.742-.307c2.1-.826,3.168,2.153,3.168,2.153-2.656,2.381-8.891,7.916-11.721,11.849,0,0,.7,1.106,1.52,2.175a3.988,3.988,0,0,1-1.588,1.536c-1.045.488-2.5.437-4.241-1.705C61.2,406.542,60.367,405.621,59.878,405.084Z"
                                transform="translate(-50.262 -384.926)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1266" data-name="Group 1266" transform="translate(91.233 544.433)">
                            <path
                                id="Path_166"
                                data-name="Path 166"
                                d="M90.978,379.724c-5.162,2.116-5.178-2.757-5.178-2.757,1.233-.552,2.374-1.207,2.374-1.207-.589-4.969-3.419-12.94-4.628-16.3,0,0,2.367-2.179,3.825-.456a4.106,4.106,0,0,0,1.388,1.094,10.04,10.04,0,0,0,6.587.2c.852-.34-1.017,1.841-6.913,2.006,0,0,1.433,3.9,1.912,6.073l1,4.685C95.105,368.048,97.5,358.7,97.5,358.7s3.84-.542,3.358,1.841a54.121,54.121,0,0,1-2,6.387,78.581,78.581,0,0,1-3.675,7.268s6.47-2.842,9.684-6.176a5.614,5.614,0,0,1-2.727-1.806c-1.533-2.438-1.1-3.057-.862-2.6.994,1.879,4.9,2.675,6.24,2.357,0,0,2.269,1.17,1.893,2.621a4.393,4.393,0,0,1-1.712,1.978,57.569,57.569,0,0,1-14.579,8.4C92.428,379.21,91.251,379.628,90.978,379.724Z"
                                transform="translate(-49.738 -296.873)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_167"
                                data-name="Path 167"
                                d="M75.075,343.376A65.021,65.021,0,0,1,65,330.432a4.071,4.071,0,0,1-.846-2.355c.193-1.4,2.733-1.578,2.733-1.578,1.118.768,5.035,1.527,6.662.237.4-.313.559.4-1.775,1.97a5.759,5.759,0,0,1-3.2.566,27.654,27.654,0,0,0,3.964,6.092c1.421,1.763,2.6,3.011,2.6,3.011-.291-3.349-.624-6.324-.594-7.811a49.543,49.543,0,0,1,.55-6.385c.449-2.283,3.806-.408,3.806-.408s-.327,2.3-.458,5.335a38.608,38.608,0,0,0,.664,9.762s1.561-2.073,2.757-3.779c1.263-1.756,4.067-4.727,4.067-4.727-5.39-2.369-6.3-4.99-5.639-4.375a10.319,10.319,0,0,0,6.174,2.262,4.159,4.159,0,0,0,1.7-.472c2-1.021,3.365,1.844,3.365,1.844C89.1,332.245,83.555,338.363,80.9,342.5c0,0,.775,1.029,1.675,2.013,0,0-2.006,4.312-5.855.374C76.506,344.7,75.6,343.862,75.075,343.376Z"
                                transform="translate(-64.135 -323.047)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1267" data-name="Group 1267" transform="translate(104.854 434.942)">
                            <path
                                id="Path_168"
                                data-name="Path 168"
                                d="M103.9,315.178c-4.925,2.607-5.425-2.247-5.425-2.247,1.17-.669,2.242-1.432,2.242-1.432-1.08-4.894-4.689-12.561-6.223-15.792,0,0,2.137-2.4,3.758-.826a4.118,4.118,0,0,0,1.491.954,10.019,10.019,0,0,0,6.571-.441c.813-.422-.829,1.933-6.678,2.67,0,0,1.813,3.746,2.506,5.864l1.465,4.57c3.238-5.356,4.687-14.909,4.687-14.909s3.765-.913,3.523,1.507a54.149,54.149,0,0,1-1.355,6.557,78.67,78.67,0,0,1-2.933,7.6s6.153-3.459,9.018-7.094a5.613,5.613,0,0,1-2.893-1.536c-1.766-2.282-1.4-2.938-1.116-2.51,1.177,1.775,5.145,2.189,6.441,1.742,0,0,2.374.946,2.144,2.43a4.373,4.373,0,0,1-1.507,2.137,57.263,57.263,0,0,1-13.667,9.786C105.29,314.525,104.161,315.054,103.9,315.178Z"
                                transform="translate(-55.242 -235.526)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_169"
                                data-name="Path 169"
                                d="M84.359,280.552a65.391,65.391,0,0,1-11.333-11.883,4.054,4.054,0,0,1-1.069-2.257c.057-1.407,2.567-1.837,2.567-1.837,1.186.653,5.161,1.022,6.655-.42.362-.35.594.34-1.576,2.134a5.776,5.776,0,0,1-3.132.878A27.575,27.575,0,0,0,81,272.833c1.618,1.615,2.921,2.741,2.921,2.741-.7-3.309-1.254-6.228-1.388-7.712a49.7,49.7,0,0,1-.068-6.4c.228-2.315,3.75-.78,3.75-.78s-.4,9.264,1.7,14.994l.782-1.336c.449-.78,1.031-1.789,1.555-2.7,1.089-1.869,3.593-5.1,3.593-5.1-5.594-1.824-6.752-4.342-6.035-3.8a10.308,10.308,0,0,0,6.364,1.641,4.134,4.134,0,0,0,1.648-.637c1.9-1.214,3.529,1.5,3.529,1.5-1.068,1.419-2.858,3.793-4.633,6.355-.887,1.282-1.768,2.609-2.557,3.893a36.128,36.128,0,0,0-2.1,3.614s.873.949,1.865,1.837c0,0-1.574,4.485-5.791.946Z"
                                transform="translate(-71.955 -260.181)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1268" data-name="Group 1268" transform="translate(107.543 324.729)">
                            <path
                                id="Path_170"
                                data-name="Path 170"
                                d="M110.331,249.779c-4.657,3.086-5.613-1.695-5.613-1.695,1.1-.784,2.1-1.648,2.1-1.648-1.55-4.763-5.829-12.031-7.716-15.093,0,0,1.824-2.6,3.626-1.2a4.237,4.237,0,0,0,1.6.8,9.9,9.9,0,0,0,6.465-1.1c.744-.5-.557,2.01-6.319,3.328,0,0,2.16,3.546,3.05,5.586l1.891,4.4a36.9,36.9,0,0,0,2.647-9.776,50.026,50.026,0,0,0,.472-5.535s3.624-1.289,3.684,1.148a39.822,39.822,0,0,1-.624,6.667,79.2,79.2,0,0,1-2.2,7.858s5.8-4.058,8.309-7.963a5.582,5.582,0,0,1-3.025-1.238c-1.961-2.093-1.71-2.785-1.367-2.386,1.378,1.649,5.34,1.663,6.6,1.089,0,0,2.452.7,2.363,2.2a4.4,4.4,0,0,1-1.3,2.28,57.758,57.758,0,0,1-12.683,11.11C111.655,248.99,110.582,249.631,110.331,249.779Z"
                                transform="translate(-54.513 -174.197)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_171"
                                data-name="Path 171"
                                d="M87.308,217.145a61.319,61.319,0,0,1-12.54-10.666,4,4,0,0,1-1.268-2.135c-.07-1.4,2.391-2.086,2.391-2.086,1.24.529,5.23.493,6.589-1.092.329-.385.622.277-1.378,2.282a5.822,5.822,0,0,1-3.039,1.19c2.457,3.668,8.2,7.608,8.2,7.608a73.636,73.636,0,0,1-2.1-7.531,49.333,49.333,0,0,1-.643-6.362c.019-2.325,3.663-1.156,3.663-1.156s.416,9.257,3.064,14.743l1.945-4.248c.914-1.97,3.118-5.437,3.118-5.437-5.735-1.247-7.115-3.633-6.352-3.163a10.256,10.256,0,0,0,6.484.986,4.2,4.2,0,0,0,1.583-.8c1.78-1.4,3.649,1.137,3.649,1.137-1.871,3.051-6.275,10.152-7.872,14.729,0,0,.982.855,2.076,1.639,0,0-1.015,4.617-5.636,1.526C89,218.153,87.93,217.516,87.308,217.145Z"
                                transform="translate(-73.499 -196.9)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1269" data-name="Group 1269" transform="translate(99.063 214.918)">
                            <path
                                id="Path_172"
                                data-name="Path 172"
                                d="M110.266,184.03c-2.172,1.778-3.617,1.5-4.506.777a4.512,4.512,0,0,1-1.273-1.886c1.007-.892,1.893-1.853,1.893-1.853-2.081-4.579-7.216-11.361-9.395-14.214,0,0,1.59-2.781,3.506-1.567a4.159,4.159,0,0,0,1.658.634,9.981,9.981,0,0,0,6.338-1.759c.707-.578-.406,2.062-5.976,3.966,0,0,2.558,3.3,3.678,5.239l2.39,4.183c2.048-5.9,1.466-15.555,1.466-15.555s3.494-1.658,3.764.765a54.094,54.094,0,0,1,.047,6.7,78.359,78.359,0,0,1-1.28,8.039s5.3-4.631,7.341-8.773a5.648,5.648,0,0,1-3.151-.921c-2.207-1.879-1.985-2.6-1.616-2.235,1.522,1.5,5.493,1.106,6.667.406,0,0,2.52.448,2.606,1.949a4.341,4.341,0,0,1-1.028,2.4,56.6,56.6,0,0,1-11.326,12.35Z"
                                transform="translate(-47.6 -113.388)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_173"
                                data-name="Path 173"
                                d="M83.7,153.79a64.749,64.749,0,0,1-13.561-9.3,4.026,4.026,0,0,1-1.5-1.987c-.223-1.386,2.149-2.322,2.149-2.322,1.292.4,5.256-.056,6.434-1.773.286-.418.65.211-1.12,2.41a5.809,5.809,0,0,1-2.893,1.5c2.848,3.384,9,6.7,9,6.7a74.682,74.682,0,0,1-2.919-7.259,49.306,49.306,0,0,1-1.338-6.249c-.237-2.311,3.516-1.533,3.516-1.533s1.43,9.149,4.666,14.322c0,0,.808-2.43,1.468-4.422.693-2.052,2.5-5.727,2.5-5.727-5.842-.641-7.475-2.867-6.665-2.478a10.289,10.289,0,0,0,6.559.3,4.213,4.213,0,0,0,1.487-.961c1.616-1.576,3.753.749,3.753.749-1.54,3.229-5.1,10.736-6.246,15.455,0,0,1.048.749,2.2,1.418,0,0-.625,4.708-5.476,2.106Z"
                                transform="translate(-68.631 -133.851)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1270" data-name="Group 1270" transform="translate(79.119 106.035)">
                            <path
                                id="Path_174"
                                data-name="Path 174"
                                d="M103.127,118.623c-3.894,3.983-5.859-.493-5.859-.493.907-.994,1.691-2.045,1.691-2.045-2.543-4.338-8.351-10.542-10.814-13.151,0,0,1.292-2.938,3.321-1.935a4.137,4.137,0,0,0,1.714.455A9.981,9.981,0,0,0,99.3,99.026c.643-.651-.19,2.1-5.528,4.584,0,0,2.886,3.017,4.2,4.824,1.266,1.761,2.811,3.912,2.811,3.912,1.423-6.092-.157-15.644-.157-15.644s3.3-2.024,3.821.361a54.238,54.238,0,0,1,.742,6.669,78.594,78.594,0,0,1-.439,8.142s4.786-5.173,6.387-9.516a5.64,5.64,0,0,1-3.229-.582c-2.388-1.637-2.243-2.376-1.839-2.053,1.67,1.334,5.575.517,6.671-.3,0,0,2.552.178,2.792,1.665a4.364,4.364,0,0,1-.772,2.5,56.7,56.7,0,0,1-9.978,13.5Z"
                                transform="translate(-34.213 -53.114)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_175"
                                data-name="Path 175"
                                d="M73.336,91.542A60.979,60.979,0,0,1,58.894,83.79c-.735-.54-1.486-1.089-1.677-1.813-.355-1.355,1.909-2.545,1.909-2.545,1.313.254,5.227-.636,6.2-2.464.228-.442.691.131-.862,2.517a5.878,5.878,0,0,1-2.726,1.808c3.152,3.055,9.577,5.683,9.577,5.683a73.215,73.215,0,0,1-3.6-6.906c-1.054-2.6-2.012-6.052-2.012-6.052-.51-2.259,3.318-1.905,3.318-1.905s.644,2.221,1.719,5.049a34.269,34.269,0,0,0,4.4,8.672s.566-2.508,1.029-4.563c.489-2.118,1.928-5.977,1.928-5.977-5.859,0-7.771-2.013-6.913-1.723a10.356,10.356,0,0,0,6.563-.425,4.165,4.165,0,0,0,1.365-1.115c1.421-1.74,3.825.327,3.825.327C81.781,75.73,78.906,83.614,78.3,88.421c0,0,1.136.627,2.365,1.162,0,0-.044,4.727-5.194,2.682Z"
                                transform="translate(-57.179 -71.334)"
                                fill="#ededed"
                            />
                        </g>
                        <g id="Group_1271" data-name="Group 1271" transform="translate(46.956)">
                            <path
                                id="Path_176"
                                data-name="Path 176"
                                d="M88.86,54.286c-3.414,4.41-5.876.174-5.876.174.789-1.094,1.447-2.231,1.447-2.231-3.02-4.034-9.473-9.548-12.225-11.852,0,0,.9-3.062,3.055-2.306a4.2,4.2,0,0,0,1.766.249A9.875,9.875,0,0,0,82.8,35.2c.547-.719.108,2.1-4.927,5.2,0,0,3.2,2.672,4.715,4.323l3.233,3.576a37.012,37.012,0,0,0-.691-10.131,49.839,49.839,0,0,0-1.315-5.43s3.02-2.4,3.853-.091A44.967,44.967,0,0,1,89.219,39.2a79.037,79.037,0,0,1,.486,8.174s4.168-5.706,5.263-10.229a5.591,5.591,0,0,1-3.271-.209c-2.552-1.36-2.5-2.109-2.062-1.836,1.815,1.137,5.594-.127,6.589-1.076,0,0,2.553-.117,2.963,1.341a4.413,4.413,0,0,1-.482,2.588,56.716,56.716,0,0,1-8.377,14.614C89.858,53.116,89.05,54.065,88.86,54.286Z"
                                transform="translate(-13.872 5.262)"
                                fill="#ededed"
                            />
                            <path
                                id="Path_177"
                                data-name="Path 177"
                                d="M55.98,31.327A63.541,63.541,0,0,1,40.711,25.58,3.933,3.933,0,0,1,38.8,24.042c-.536-1.268,1.55-2.734,1.55-2.734,1.345.066,5.086-1.318,5.829-3.248.179-.47.679.045-.528,2.579a5.853,5.853,0,0,1-2.461,2.128c3.57,2.532,10.232,4.314,10.232,4.314-2-2.675-3.7-5.051-4.46-6.308a47.934,47.934,0,0,1-2.75-5.673c-.766-2.16,3.053-2.325,3.053-2.325s.88,2.114,2.3,4.75a37.248,37.248,0,0,0,5.507,7.938s.249-2.546.455-4.635c.2-2.139,1.09-6.117,1.09-6.117-5.819.782-7.921-.96-7.045-.784a10.372,10.372,0,0,0,6.432-1.291,4.19,4.19,0,0,0,1.216-1.285c1.2-1.914,3.818-.179,3.818-.179-.7,3.485-2.454,11.6-2.524,16.445,0,0,1.2.475,2.487.848,0,0,.533,4.675-4.823,3.3C57.9,31.712,56.686,31.47,55.98,31.327Z"
                                transform="translate(-38.712 -10.452)"
                                fill="#ededed"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}
