import { BoxProps, Wrap, WrapItem } from '@chakra-ui/react';
import { Tag } from '@prisma/client';
import { noop } from 'lodash';
import TagComponent from './Tag';

type TagListProps = BoxProps & {
    tags: Tag[];
    isClearable?: boolean;
    onClear?: (tag: Tag) => void;
};

export default function TagList({ tags = [], isClearable = false, onClear = noop, ...rest }: TagListProps) {
    return (
        <Wrap {...rest} spacing={1} wrap="wrap" justify="flex-start">
            {tags.map((tag) => (
                <WrapItem key={tag.id}>
                    <TagComponent isClearable={isClearable} onClear={() => onClear(tag)}>
                        {tag.name}
                    </TagComponent>
                </WrapItem>
            ))}
        </Wrap>
    );
}
