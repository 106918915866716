import { format, parse } from 'date-fns';
import { parseFromTimeZone, convertToTimeZone } from 'date-fns-timezone';
import { DEFAULT_TIMEZONE, LOCAL_API_DATE_FORMAT, LOCAL_CLIENT_DATE_FORMAT } from 'lib/constants';

export const formatLocalDateForClient = (date: Date) => {
    return format(parseDateIfString(date, true) as Date, LOCAL_CLIENT_DATE_FORMAT);
};

export const formatLocalDateForAPI = (date: Date | null) => {
    if (!date) {
        return null;
    }
    if (typeof date === 'string') {
        const [datePart] = (date as string).split('T');
        return datePart;
    }
    return format(date, LOCAL_API_DATE_FORMAT);
};

export const parseDateFromAPI = (date: string) => {
    if (!date) {
        return null;
    }
    return parseFromTimeZone(date, { timeZone: DEFAULT_TIMEZONE });
};

export const dateAsUTCDate = (date: Date | string | number | null): Date | null => {
    if (!date) {
        return null;
    }
    return convertToTimeZone(date, { timeZone: DEFAULT_TIMEZONE });
};

export const dateAsZeroedUTCString = (date: Date | null | undefined): string | null => {
    if (!date) {
        return null;
    }

    const formattedDate = format(date, LOCAL_API_DATE_FORMAT);
    return `${formattedDate}T00:00:00.000Z`;
};

export const dateAsLocalDate = (date: string | Date): Date | null => {
    if (!date) {
        return null;
    }
    if (typeof date === 'string') {
        const [datePart] = date.split('T');
        return parse(datePart, LOCAL_API_DATE_FORMAT, new Date());
    }
    return date;
};

export function parseDateIfString(date: Date | string | null, local = false) {
    if (date) {
        if (typeof date === 'string') {
            return local ? dateAsLocalDate(date) : dateAsUTCDate(date);
        }
        return date;
    }
}
