import { Flex, Heading, Text } from '@chakra-ui/react';

interface InfoSectionProps {
    title: string;
    subtitle: string;
    description: string[];
}

export default function InfoSection({ title, subtitle, description = [] }: InfoSectionProps) {
    return (
        <Flex direction="column" align={{ base: 'center', lg: 'unset' }}>
            <Text fontSize="lg" mb={2} fontFamily="Montserrat">
                {title}
            </Text>
            <Heading as="h2" maxWidth={{ lg: '393px' }} mb="3" textAlign={{ base: 'center', lg: 'left' }}>
                {subtitle}
            </Heading>
            {description.map((text) => (
                <Text
                    fontSize="lg"
                    maxWidth="560px"
                    px={{ base: 4, lg: 0 }}
                    key={text}
                    mb={2}
                    textAlign={{ base: 'center', lg: 'left' }}
                >
                    {text}
                </Text>
            ))}
        </Flex>
    );
}
