import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    useDisclosure,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    useColorModeValue,
    Input,
    InputProps,
    InputGroup,
    InputRightElement,
    Portal,
} from '@chakra-ui/react';
import Calendar, { OnChangeDateCallback } from 'react-calendar';
import { formatLocalDateForClient } from 'lib/utils/date';
import { CloseIcon } from '@chakra-ui/icons';

type DatePickerProps = Omit<InputProps, 'value' | 'onChange'> & {
    value?: Date;
    minDate?: Date;
    maxDate?: Date;
    startDate?: Date;
    isClearable?: boolean;
    renderInPortal?: boolean;
    onChange?: OnChangeDateCallback;
};

export default function DatePicker(props: DatePickerProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        value,
        onChange,
        minDate,
        maxDate,
        isClearable = false,
        renderInPortal = false,
        startDate,
        ...rest
    } = props;

    const [activeDate, setActiveDate] = useState(value || startDate);

    useEffect(() => setActiveDate(value || startDate), [startDate, value]);

    const className = useColorModeValue('light', 'dark');

    const content = (
        <PopoverContent>
            <PopoverBody>
                <Calendar
                    value={value}
                    minDate={minDate}
                    maxDate={maxDate}
                    className={className}
                    onChange={(date: Date, evt: ChangeEvent<HTMLInputElement>) => {
                        if (onChange) {
                            onChange(date, evt);
                        }
                        onClose();
                    }}
                    activeStartDate={activeDate}
                    onActiveStartDateChange={({ activeStartDate }) => setActiveDate(activeStartDate)}
                    locale="en-US"
                />
            </PopoverBody>
        </PopoverContent>
    );

    return (
        <Popover isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
                <InputGroup cursor="pointer">
                    <Input
                        {...rest}
                        onClick={onOpen}
                        value={value ? formatLocalDateForClient(value) : ''}
                        cursor="pointer"
                        readOnly
                    />
                    {isClearable && (
                        <InputRightElement>
                            {value && (
                                <CloseIcon
                                    fontSize="xs"
                                    onClick={(evt) =>
                                        onChange &&
                                        onChange(
                                            null as unknown as Date,
                                            evt as unknown as ChangeEvent<HTMLInputElement>,
                                        )
                                    }
                                />
                            )}
                        </InputRightElement>
                    )}
                </InputGroup>
            </PopoverTrigger>
            {renderInPortal ? <Portal>{content}</Portal> : content}
        </Popover>
    );
}
