import { Button, IconButton } from '@chakra-ui/button';
import { FormLabel } from '@chakra-ui/form-control';
import { CloseIcon, CopyIcon, InfoIcon } from '@chakra-ui/icons';
import { Flex, Text } from '@chakra-ui/layout';
import {
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    ButtonProps,
    PlacementWithLogical,
    Portal,
} from '@chakra-ui/react';
import { Select } from '@chakra-ui/select';
import { DarkButton } from 'components/Buttons';
import DatePicker from 'components/DatePicker/DatePicker';
import { signIn, useSession } from 'next-auth/react';
import { useState } from 'react';

export type OnCopyFunction = (date: Date, mode: string) => void;

function CopyHelper() {
    return (
        <Popover>
            <PopoverTrigger>
                <IconButton aria-label="Color Help" icon={<InfoIcon />} variant="ghost" />
            </PopoverTrigger>
            <PopoverContent>
                <PopoverBody>
                    <Text>All other activities will be then distributed according to the template automatically.</Text>
                </PopoverBody>
            </PopoverContent>
        </Popover>
    );
}

type StartEndSelectProps = {
    value: string;
    setValue: (value: string) => void;
};

function StartEndSelect({ value, setValue }: StartEndSelectProps) {
    return (
        <Select display="table-cell" size="sm" value={value} onChange={(evt) => setValue(evt.target.value)}>
            <option value="Start">Start</option>
            <option value="End">End</option>
        </Select>
    );
}

type CopyButtonProps = ButtonProps & {
    isOpen: boolean;
    isCopying: boolean;
    placement?: PlacementWithLogical;
    onClose: () => void;
    onOpen: () => void;
    onClone: OnCopyFunction;
};

export default function CopyButton({
    isOpen,
    isCopying,
    placement = 'bottom-end',
    onClose,
    onOpen,
    onClone: onClick,
    ...rest
}: CopyButtonProps) {
    const [date, setDate] = useState(new Date());
    const [mode, setMode] = useState('Start');

    const { data: session } = useSession();

    return (
        <Popover placement={placement} closeOnBlur={false} isOpen={isOpen} onClose={onClose}>
            <PopoverTrigger>
                <Button
                    {...rest}
                    leftIcon={isOpen ? <CloseIcon fontSize="xs" /> : <CopyIcon />}
                    onClick={(evt) => {
                        evt.stopPropagation();
                        if (!session) {
                            return signIn();
                        }
                        return isOpen ? onClose() : onOpen();
                    }}
                >
                    {isOpen ? 'Cancel' : 'Copy'}
                </Button>
            </PopoverTrigger>
            <Portal>
                <PopoverContent onClick={(evt) => evt.stopPropagation()}>
                    <PopoverBody>
                        <FormLabel>
                            <span>
                                I want my roadmap to <StartEndSelect value={mode} setValue={setMode} /> at:
                            </span>
                        </FormLabel>
                        <Flex alignItems="center">
                            <DatePicker value={date} onChange={setDate} mr={2} />
                            <CopyHelper />
                        </Flex>
                        <Flex justify="flex-end" mt={4}>
                            <DarkButton
                                size="sm"
                                disabled={isCopying}
                                isLoading={isCopying}
                                onClick={() => onClick(date, mode)}
                            >
                                Create copy {'&'} edit
                            </DarkButton>
                        </Flex>
                    </PopoverBody>
                </PopoverContent>
            </Portal>
        </Popover>
    );
}
