export default function RoadmapPlaceholder() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="250"
            viewBox="0 0 259 93"
        >
            <defs>
                <linearGradient
                    id="linear-gradient-roadmap-placeholder"
                    y1="0.5"
                    x2="1"
                    y2="0.5"
                    gradientUnits="objectBoundingBox"
                >
                    <stop offset="0" stopColor="#e0e0e0" />
                    <stop offset="1" stopColor="#bfbfbf" />
                </linearGradient>
            </defs>
            <g id="Group_951" data-name="Group 951" transform="translate(-152 -353)">
                <path
                    id="Rectangle_45"
                    data-name="Rectangle 45"
                    d="M4,0H255a4,4,0,0,1,4,4V93a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V4A4,4,0,0,1,4,0Z"
                    transform="translate(152 353)"
                    fill="#f0f0f0"
                />
                <rect
                    id="Rectangle_46"
                    data-name="Rectangle 46"
                    width="147"
                    height="15"
                    rx="4"
                    transform="translate(173 374)"
                    fill="url(#linear-gradient-roadmap-placeholder)"
                />
                <rect
                    id="Rectangle_49"
                    data-name="Rectangle 49"
                    width="116"
                    height="15"
                    rx="4"
                    transform="translate(281 398)"
                    fill="url(#linear-gradient-roadmap-placeholder)"
                />
                <rect
                    id="Rectangle_50"
                    data-name="Rectangle 50"
                    width="187"
                    height="15"
                    rx="4"
                    transform="translate(173 423)"
                    fill="url(#linear-gradient-roadmap-placeholder)"
                />
            </g>
        </svg>
    );
}
