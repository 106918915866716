import { Tag, TagCloseButton, TagLabelProps, useColorModeValue } from '@chakra-ui/react';

type TagProps = TagLabelProps & {
    isClearable?: boolean;
    onClear?: () => void;
};

export default function TagComponent({ children, isClearable, onClear, ...props }: TagProps) {
    const bgColor = useColorModeValue('gray.200', 'gray.600');
    return (
        <Tag bgColor={bgColor} {...props}>
            {children}
            {isClearable && <TagCloseButton onClick={onClear} />}
        </Tag>
    );
}
