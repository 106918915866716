import { sample } from 'lodash';
import { COLORS } from './constants';

type RGBType = { r?: number | null; g?: number | null; b?: number | null };

function hexToRgb(hex: string): RGBType {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    if (result) {
        return { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) };
    }
    return { r: null, g: null, b: null };
}

function isLightColor(hex: string): boolean {
    const { r, g, b } = hexToRgb(hex);
    if (!r || !g || !b) {
        return false;
    }
    return r * 0.299 + g * 0.587 + b * 0.114 > 186;
}

export function getFontColorForBackground(hex: string | null): string {
    return isLightColor(hex || '#FFFFFF') ? '#1A202C' : '#FFFFFF';
}

export function getBgColorForColor(hex: string | null): string {
    return isLightColor(hex || '#FFFFFF') ? 'whiteAlpha.200' : 'blackAlpha.200';
}

export function getRandomColor() {
    return sample(COLORS) as string;
}
